<template>
    <div class="home">
        <el-form class="search_box" ref="form" :model="search" label-width="70px" size='medium' :inline="true">
            <el-form-item label="手机号" label-width="100px">
                <el-input v-model="search.mobile"></el-input>
            </el-form-item>
            <el-form-item label="排序" label-width="100px">
                <el-select v-model="search.sort" style="width: 100%">
                    <el-option v-for="item in sortList" :label='item.name' :value='item.id' :key='item.id'></el-option>
                </el-select>
            </el-form-item>




            <!-- 全部 待发货  待收货  未支付  已完成  已取消 -->
            <el-form-item label-width="0">
                <el-button size='medium' type='primary' @click="serchFn">搜索</el-button>
                <el-button size='medium' type='primary' @click="emptys">重置</el-button>
            </el-form-item>
        </el-form>
        <div class="posr">
            <el-table ref="multipleTableRef" v-loading="loadingShow" :data="tableData" style="width: 100%;margin-top: 20px;"
                :header-cell-style="{ background: '#F7F8FA', color: '#333333' }">
                <el-table-column prop="mobile" label="手机号码" align="center">
                </el-table-column>
                <el-table-column prop="createtime" label="注册时间" align="center">
                </el-table-column>
                <el-table-column prop="invateuser" label="邀请人" align="center">
                </el-table-column>
                <el-table-column prop="token" label="剩余token" align="center">
                </el-table-column>
                <el-table-column prop="qcount" label="提问总次数" align="center">
                </el-table-column>
                <el-table-column prop="tcount" label="消耗总token数" align="center">
                </el-table-column>

                <el-table-column align="center" fixed="right" width="160px" label="操作">
                    <template slot-scope="scope">
                        <el-button type="text" @click="roleTypeFn(scope.row)">奖励</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <div class="footer-pagin">
                <el-pagination :current-page.sync="search.page" :page-sizes="pageSizes" :pager-count="5"
                    :page-size="search.pageSize" layout="total, sizes, prev, pager, next, jumper" :total="total"
                    @size-change="handleSizeChange" @current-change="handleCurrentChange" />
            </div>
        </div>

        <el-dialog title="奖励" :visible.sync="roleTypeVisible" @close="closeDialog" width="40%">
            <div>
                <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="120px" class="demo-ruleForm">
                    <el-form-item label="奖励数量" prop="token">
                        <el-input-number v-model="ruleForm.token" :min="1" :precision="0" :controls='false'></el-input-number>
                    </el-form-item>
                </el-form>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="roleTypeVisible = false">取 消</el-button>
                <el-button type="primary" @click="submitForm('ruleForm')">确 定</el-button>
            </span>
        </el-dialog>


    </div>
</template>
  
<script>
import { GetUserPage, RewardToken } from '@/service/userManage/userManage.js'
// @ is an alias to /src
import { Message} from 'element-ui'
export default {
    name: 'Home',
    components: {
        
    },
    data() {
        return {
            loadingShow: false,
            roleTypeVisible: false,
            ruleForm: {},
            rules: {
                token: [
                    { required: true, message: '请填写奖励数量', trigger: 'blur' },
                ],
            },
            id: '',
            tableData: [],
            search: {
                page: 1,
                pageSize: 10,
            },
            pageSizes: [10, 20, 50, 100],
            total: 0,
            sortList: [{name: '剩余token降序',id: 1},{name: '剩余token升序',id: 2},{name: '提问总次数降序',id: 3},{name: '提问总次数升序',id: 4},{name: '提问总消耗降序',id: 5},{name: '提问总消耗升序',id: 6}]
        }
    },
    created() {
        this.gettableList();
    },
    methods: {
        
        
        roleTypeFn(row) {
            this.id = row ? row.id : ''
            this.roleTypeVisible = true
        },
        
        closeDialog() {
            this.ruleForm = {}
        },
        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (!valid) return;
                this.loadingShow = true
                RewardToken({ userId: this.id,token: this.ruleForm.token }).then(res => {
                    this.loadingShow = false;
                    console.log(res)
                    this.roleTypeVisible = false
                    Message.success(`奖励成功`)
                    this.gettableList()
                }).catch(err => {
                    console.log(err)
                    this.loadingShow = false;
                })

            });
        },
        serchFn() {
            this.gettableList();
        },
        emptys() {
            for (const key in this.search) {
                this.search[key] = undefined
            }
            this.search.page = 1
            this.search.pageSize = 10
            this.serchFn()
        },
        gettableList() {
            this.loadingShow = true
            const query = {
                ...this.search
            }

            GetUserPage(query).then(res => {
                this.loadingShow = false;
                console.log(res)
                this.tableData = res.data.list
                this.total = res.data.count
            }).catch(err => {
                console.log(err)
                this.loadingShow = false;
            })
        },
        // 每页的条数
        handleSizeChange(val) {
            this.search.pageSize = val;
            this.gettableList();
        },
        // 分页
        handleCurrentChange(val) {
            this.search.page = val;
            this.gettableList();
        },
    }
}
</script>
<style lang="less" scoped>
.posr {
    position: relative;
}

.footer-pagin {
    margin: 20px;
    text-align: right;
}

.t-c {
    text-align: center;
}
.questions-box{
    margin-bottom: 15px;
    padding-right: 30px;
    position: relative;
    .el-icon-delete{
        position: absolute;
        right: -30px;
        font-size: 16px;
        top: 0;
        color: red;
        cursor: pointer;
    }
}
</style>
  
  